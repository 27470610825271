import { SectionContainer } from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  TitleMed,
  WhiteLink,
} from "components/rhombus-UI/theme/typography";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css, cx } from "linaria";
import { styled } from "linaria/react";
import React, { useState } from "react";
import { FreeMode, Mousewheel, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Arrow from "./icon-components/Arrow";
import arrowSvg from "./img/blue-arrow.png";

const DeviceCardsContainer = styled(SectionContainer)`
  .swiper,
  .section-title {
    padding: 0 1.25rem;
  }
  @media (max-width: 1279px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 600px) {
    .section-title {
      text-align: center;
    }
  }
`;

const SliderSectionInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  @media (min-width: 1513px) {
    max-width: 1390px;
  }
`;

const cardContainer = css`
  width: 100%;
  display: flex;

  .swiper-wrapper {
    display: flex;
    width: 100% !important;
    transform-style: preserve-3d !important;
    -webkit-transform-style: preserve-3d !important;
    backface-visibility: hidden !important;
    -webkit-backface-visibility: hidden !important;
  }
  :not(:has(.swiper-scrollbar-lock)) {
    .swiper-wrapper {
      padding-bottom: 2.5rem;
    }
  }
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 0.5rem;
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background-color: var(--nuetral-200);
    border-radius: 1rem;
    .swiper-scrollbar-drag {
      background-image: var(--gradient-base);
      border-radius: 1rem;
      width: 100%;
      cursor: grab;
    }
  }
`;

const cardImage = css`
  // Gatbsy Image styles must be forced...I know not best practice
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 0 !important;
  transition: all 300ms ease-in-out !important;
`;

const slideStyles = css`
  height: 550px !important;
  background-color: var(--nuetral-200);
  min-width: calc((100% - (20px * var(--width))) / var(--width)) !important;
  flex-grow: 1;
  border-radius: 1.5rem;
  overflow: hidden;
  position: relative;

  //----Sarafi Glitch------
  transform: translateZ(0) !important;
  -webkit-transform: translateZ(0) !important;
  transform-style: preserve-3d !important;
  -webkit-transform-style: preserve-3d !important;
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden !important;
  //-----------------------

  :hover .${cardImage} {
    transform: scale(1.1) !important;
  }
  .device-card-link img {
    transition-delay: 100ms;
    transition-timing-function: ease-in-out;
  }
  :hover .device-card-link img {
    margin-left: 5px;
  }

  @media (max-width: 820px) {
    flex-direction: column;
    min-width: unset;
    flex-basis: unset;
    height: 400px !important;
  }
`;

const recommendStyles = cx(
  slideStyles,
  css`
    height: 400px;
  `
);

const cardInner = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  padding-bottom: 4rem;
  justify-content: flex-end;
  z-index: 1;
  text-decoration: none;
  -webkit-appearance: none;
  background-image: linear-gradient(to top, var(--blue-900), transparent 80%);
  touch-action: none;
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 90%;
  }
`;

const textContainer = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 300ms ease-in-out;
  width: calc(100% - 2rem);
  max-width: 400px;
  h2 {
    font-size: 26px !important;
  }

  @media (max-width: 820px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const mobileTextContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  width: calc(100% - 20px - 2rem);
  @media (max-width: 600px) {
    h2 {
      font-size: 14px !important;
    }
  }
`;

const MobileArrowLink = styled.a`
  color: var(--white);
  width: 24px;
  aspect-ratio: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeviceCards = ({
  data,
  recommend = false,
  deviceType = null,
  color = "var(--white)",
  center = false,
  use4Cards = false,
}) => {
  const [currentSlideName, setCurrentSlideName] = useState(null);
  const { isMobile } = useIsMobile(820);

  const handleMouseEnter = name => {
    setCurrentSlideName(name);
  };

  const handleMouseLeave = () => {
    setCurrentSlideName(null);
  };

  const renderDesktopCardContent = item => {
    return (
      <div
        style={
          currentSlideName === item.name
            ? { transform: "translateY(0px)" }
            : { transform: "translateY(100px)" }
        }
        className={textContainer}
      >
        <TitleMed color="var(--white)">{item.name}</TitleMed>
        <div
          style={
            currentSlideName === item.name ? { opacity: 1 } : { opacity: 0 }
          }
          className={textContainer}
        >
          <MainParagraph color="var(--white)">{item.description}</MainParagraph>
          <WhiteLink href={item.link} className="device-card-link">
            Learn More
            <img src={arrowSvg} />
          </WhiteLink>
        </div>
      </div>
    );
  };

  const renderMobileCardContent = item => {
    return (
      <div className={mobileTextContainer}>
        <TitleMed color="var(--white)">{item.name}</TitleMed>
        <MobileArrowLink as={Link} to={item.link}>
          <Arrow />
        </MobileArrowLink>
      </div>
    );
  };

  return (
    <DeviceCardsContainer style={{ background: color }} id="cards">
      <SliderSectionInner>
        {recommend ? (
          <TitleMed
            style={
              center || isMobile
                ? { textAlign: "center" }
                : { textAlign: "left", width: "100%" }
            }
            className="section-title"
          >
            Explore Other {deviceType} Models
          </TitleMed>
        ) : (
          <FancyTitle
            style={
              center || isMobile
                ? { textAlign: "center" }
                : { textAlign: "left", width: "100%" }
            }
            className="section-title"
          >
            {data.title}
          </FancyTitle>
        )}
        <Swiper
          freeMode
          slidesPerView="auto"
          spaceBetween={20}
          scrollbar={{ draggable: true }}
          mousewheel={{ releaseOnEdges: true, invert: false }}
          breakpoints={{
            320: {
              slidesPerView: 1.5,
              scrollbar: {
                enabled: true,
                draggable: true,
              },
            },
            425: {
              slidesPerView: 2.15,
              scrollbar: {
                enabled: true,
                draggable: true,
              },
            },
            625: {
              slidesPerView: 2.25,
              scrollbar: {
                enabled: true,
                draggable: true,
              },
            },
            1024: {
              slidesPerView: 3,
              scrollbar: {
                enabled: true,
                draggable: true,
              },
            },
            ...(use4Cards && {
              1280: {
                slidesPerView: 4,
                scrollbar: {
                  enabled: true,
                  draggable: true,
                },
              },
            }),
          }}
          modules={[Scrollbar, Mousewheel, FreeMode]}
          className={cardContainer}
        >
          {data.cards.map(item => (
            <SwiperSlide
              className={recommend ? recommendStyles : slideStyles}
              style={{ "--width": item.flex }}
              key={item.name}
              onMouseEnter={() => handleMouseEnter(item.name)}
              onMouseLeave={handleMouseLeave}
            >
              <GatsbyImage
                image={getImage(item.image)}
                alt={item.name}
                className={cardImage}
                objectPosition="center"
                objectFit="cover"
              />
              <Link to={item.link} className={cardInner}>
                {isMobile
                  ? renderMobileCardContent(item)
                  : renderDesktopCardContent(item)}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </SliderSectionInner>
    </DeviceCardsContainer>
  );
};

export default DeviceCards;
