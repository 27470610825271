import AccordionSection from "components/common/AccordionSection";
import DeviceCards from "components/common/DeviceCards";
import FAQSection from "components/common/FAQSection";
import Features from "components/common/Features";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import InfoSlider from "components/common/InfoSlider";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

import GlobalLayout from "../../components/page/GlobalLayout";
import CTABanner2 from "components/common/footer-banners/CTABanner2";
import { Helmet } from "react-helmet";

export default function Cameras() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "security cameras",
        title: "Secure Your Spaces with All-in-One Cloud Cameras",
        description:
          "Protect your organization and modernize security management with intelligent cloud cameras. With plug-and-play deployment, onboard analytics, and native remote access, it’s easy to secure your spaces with confidence.",
        image: data.headerImage,
        button1: {
          text: "Compare Cameras",
          path: "/cameras/compare-cameras/",
        },
      },
      cardSection: {
        title: "Explore Cloud-Based Security Cameras",
        cards: [
          {
            image: data.device1,
            name: "Dome Cameras",
            description:
              "Modern enterprise performance with edge-based analytics",
            link: "/cameras/dome-cameras/",
            flex: 4,
          },
          {
            image: data.device2,
            name: "Fisheye Cameras",
            description:
              "Immersive coverage and visibility with minimal camera placement",
            link: "/cameras/fisheye-cameras/",
            flex: 4,
          },
          {
            image: data.device3,
            name: "Bullet Cameras",
            description: "Ruggedized form-factor with high-resolution video",
            link: "/cameras/bullet-cameras/",
            flex: 4,
          },
          {
            image: data.device4,
            name: "Multisensor Cameras",
            description: "One powerful camera for complete coverage",
            link: "/cameras/multisensor-cameras/",
            flex: 4,
          },
        ],
      },
      infoSlides: [
        {
          tabTitle: "Remote Access",
          preTitle: "Flexible Cloud Management",
          title: "Native Remote Access",
          description:
            "See and manage your video security anytime, anywhere with cloud technology. Remotely manage cameras, alarms, sensors, and integrations from a centralized platform to improve visibility and operations at scale.",
          image: data.infoSlide1,
        },
        {
          tabTitle: "Onboard Analytics",
          preTitle: "Smarter, Faster Performance",
          title: "Onboard Analytics",
          description:
            "Get crystal-clear footage and lightning-fast processing right out of the box. Using a suite of powerful AI analytics and machine learning, footage is analyzed directly on the camera to save time, optimize bandwidth, and accelerate live alerts. ",
          image: data.infoSlide2,
        },
        {
          tabTitle: "Automatic Updates",
          preTitle: "Save Time & Resources",
          title: "Automatic Firmware Updates",
          description:
            "Security management has never been more stress-free. Save time and resources with automatic firmware updates that keep your devices up to date with the latest features, optimizations, and security protocols—all with minimal maintenance and no manual work on your part.",
          image: data.infoSlide3,
        },
        {
          tabTitle: "Bandwidth-Friendly",
          preTitle: "Flexible Cloud Management",
          title: "Bandwidth-Friendly",
          description:
            "Utilizing 50% less bandwidth and providing 10x better latency compared to other cloud vendors, Rhombus sets the standard for cloud-security performance. Better true-to-life video quality makes it easier to get the visibility you need at nearly instantaneous speeds.",
          image: data.infoSlide4,
        },
      ],
      accordionSection: {
        title: "Save Time and Simplify with Plug-and-Play Cloud Cameras",
        items: [
          {
            title: "Set Up In Minutes",
            p:
              "Say goodbye to NVR/DVRs and bulky hardware. Start protecting your sites in minutes with just a camera, power source, and network connection.",
            img: data.drawer1,
            alt: "Set Up In Minutes",
          },
          {
            title: "Unlimited Scalability",
            p:
              "Deploy and manage thousands of cameras from a single pane of glass with unparalleled reliability, video quality, and processing speed.",
            img: data.drawer2,
            alt: "Unlimited Scalability",
          },
          {
            title: "Built to Last",
            p:
              "Enjoy decades of enterprise-grade performance with hardware engineered in-house and backed by an industry-leading 10-year warranty.",
            img: data.drawer3,
            alt: "Built to Last",
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "Secure by Default",
          title: "Secure by Default",
          p:
            "With enterprise-grade encryption and regular security audits, Rhombus puts your security first.",
        },
        {
          icon: data.icon2,
          iconAlt: "Simple Setup",
          title: "Simple Setup",
          p:
            "No NVR/DVRs required. Rhombus cameras connect via PoE and come online in minutes.",
        },
        {
          icon: data.icon3,
          iconAlt: "Built with Quality in Mind",
          title: "Built with Quality in Mind",
          p:
            "All cameras use enterprise-grade components and include 10-year warranties for complete peace-of-mind.",
        },
        {
          icon: data.icon4,
          iconAlt: "Automatic Updates",
          title: "Automatic Updates",
          p:
            "Stay ahead of threats and protect your organization with the best security technology.",
        },
      ],
      QA: [
        {
          question: "How much bandwidth do cloud cameras need?",
          answer:
            "During regular camera operation, upload bandwidth consumption is 10 - 30kbps. When a camera is being actively streamed, the bandwidth used depends on the amount of motion and type of camera. Bandwidth used over the WAN typically averages between 120 and 1400 Kbps, depending on the camera type and resolution. If the camera is locally streaming over the LAN, nearly 0 bandwidth is consumed.",
        },
        {
          question: "How many cameras do I need?",
          answer:
            "Every organization is unique, and the number of cameras you need completely depends on your size, configuration, and security needs. There’s no minimum or maximum to the number of cameras you deploy—whether you have one or thousands of devices, they all can be managed from a single pane of glass. Our team of experts can help you determine the right solution for your specific needs.",
          link: { text: "Book a Demo", path: "/live-demo/" },
        },
        {
          question: "What if I have a problem with a camera?",
          answer:
            "If you experience an issue with a camera for any reason, our in-house Customer Support Team is available 24/7 to assist you via email, phone, and ticket. Most issues can be resolved remotely, and all Rhombus cameras are covered by our 10-Year Warranty and our same-day RMA program. ",
          link: {
            text: "Learn More",
            path: "https://support.rhombussystems.com/hc/en-us",
          },
        },
        {
          question: "What if my internet goes down?",
          answer:
            "If you lose your internet connection—don’t worry! During a network disconnection and while the cameras still have power, Rhombus cameras will still record footage and save the footage to the camera’s onboard storage. Once the connection has been restored, the footage will be resynchronized with the cloud so you don’t lose footage or data. ",
          link: {
            text: "Learn More",
            path:
              "https://support.rhombussystems.com/hc/en-us/articles/7011505258637-What-happens-when-your-internet-goes-down-#:~:text=across%20the%20network.-,Make%20sure%20all%20the%20switches%20are%20connected%20to%20the%20correct,still%20showing%20a%20disconnection%20error.",
          },
        },
      ],
    };

    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Commercial Security Cameras | Video Surveillance System | Rhombus
          </title>
          <meta
            name="description"
            content="Explore cloud commercial security cameras for real-time monitoring and plug-and-play setup. A video surveillance system with AI analytics and remote access. Request a demo today."
          />
        </Helmet>
        <HeroLayout1 data={pageData.headerSection} bottom reverse />
        <DeviceCards data={pageData.cardSection} use4Cards />
        <InfoSlider
          data={pageData.infoSlides}
          title="Modernize Your Video Security for Visibility Anytime, Anywhere"
          color="var(--nuetral-100)"
        />
        <AccordionSection data={pageData.accordionSection} reverse />
        <Features
          data={pageData.features}
          color="var(--nuetral-100)"
          title="Camera Features"
        />
        <FAQSection
          color="var(--nuetral-100)"
          image={data.faqImage}
          reverse
          title="FAQs"
          QA={pageData.QA}
        />
        <CTABanner2 />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/cameras/img/cameras-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(
        relativePath: { eq: "components/demo/img/secure-by-default.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/plug.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: {
          eq: "components/industries/img/icons/certification.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/updates.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      drawer1: file(
        relativePath: { eq: "components/cameras/img/r600-setup-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      drawer2: file(
        relativePath: { eq: "components/cameras/img/scalability-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      drawer3: file(
        relativePath: { eq: "components/cameras/img/dome-camera-rain-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: { eq: "components/cameras/img/R100-edu-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: { eq: "components/ITPage/img/secure-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/ITPage/img/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      device1: file(
        relativePath: { eq: "components/cameras/img/dome-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device2: file(
        relativePath: { eq: "components/cameras/img/fisheye-cameras-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device3: file(
        relativePath: { eq: "components/cameras/img/bullet-cameras-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device4: file(
        relativePath: { eq: "components/cameras/img/r600-card-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      faqImage: file(
        relativePath: { eq: "components/cameras/img/r600-console.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
