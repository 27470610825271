import React, { useState } from "react";
import { css, cx } from "linaria";
import SlideInContainer from "components/common/SlideInContainer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PageContent from "components/page/PageContent";
import useIsMobile from "hooks/useIsMobile";
import Up from "../../images/carrot-up.png";
import {
  FancyTitleMed,
  PreTitle,
  TitleMed,
} from "components/rhombus-UI/theme/typography";

const sectionClass = css`
  background-color: var(--nuetral-100);
  display: flex;
  justify-content: center;
`;

const contentClass = css`
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  padding: 5rem 1.25rem;
  gap: 4rem;
`;

const accordionClass = css`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 1200px) {
    width: 95%;
  }
`;

const textClass = css`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 515px;
  width: 100%;
  @media (max-width: 1200px) {
    max-width: unset;
  }

  ul {
    list-style: none;
    padding: unset;
    margin: unset;

    li {
      cursor: pointer;
    }
  }
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

const lineClass = css``;
const lineClassActive = css`
  cursor: default;
`;

const borderClass = css`
  border-top: 1px solid var(--nuetral-200);
`;

export const borderActive = cx(
  borderClass,
  css`
    border-color: var(--blue-500);
    border-width: 3px;
  `
);

const subHeaderClass = css`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0px 1rem 0.5rem;
  transition: all 500ms ease;

  &:hover {
    background: var(--blue-100);
  }

  @media (max-width: 700px) {
    padding: 1rem 0px 1rem 0.5rem;
  }

  span {
    color: var(--nuetral-900);
    font-weight: 900;
    font-size: 18px;
    width: 75%;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
`;

const subHeaderClassActive = cx(
  subHeaderClass,
  css`
    transition: all 500ms ease;
  `
);
const stateClass = css`
  position: absolute;
  display: flex;
  top: 50%;
  right: 2%;
  width: 11px;
  transform: translateY(-50%);
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.47, 0.42, 0, 0.99);

  &:after {
    content: "";
    position: relative;
    min-width: 11px;
    width: 11px;
    height: 11px;
    background-image: url(${Up});
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 500ms cubic-bezier(0.47, 0.42, 0, 0.99);
  }
`;

const openClass = cx(stateClass);

const closedClass = cx(
  stateClass,
  css`
    &:after {
      transform: rotate(-180deg);
    }
  `
);

const paragraphClass = css`
  width: 95%;
  color: var(--nuetral-900);
  line-height: 2;
  overflow: hidden;
  max-height: 0;
  margin-top: 0;
  opacity: 0;
  transition: max-height 1s cubic-bezier(0.47, 0.42, 0, 0.99),
    margin-top 1s cubic-bezier(0.47, 0.42, 0, 0.99),
    opacity 1s cubic-bezier(0.47, 0.42, 0, 0.99);
  padding-left: 0.5rem;
  ul {
    list-style-type: disc !important;
    padding-left: 1.25rem;
  }
`;

const paragraphClassInactive = cx(
  paragraphClass,
  css`
    overflow: hidden;
    max-height: 0;
    margin-top: 0;
    opacity: 0;
    transition: max-height 1s cubic-bezier(0.47, 0.42, 0, 0.99),
      margin-top 1s cubic-bezier(0.47, 0.42, 0, 0.99),
      opacity 1s cubic-bezier(0.47, 0.42, 0, 0.99);
  `
);

const paragraphClassActive = cx(
  paragraphClass,

  css`
    max-height: 400px;
    margin-bottom: 2rem;
    color: rgba(6, 6, 6, 0.7);
    opacity: 1;
  `
);

const videoContainerClass = css`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const imageWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const imgClass = css`
  display: none !important;
  opacity: 0 !important;
  pointer-events: none !important;
`;

const imgClassActive = cx(
  imgClass,
  css`
    display: block !important;
    opacity: 1 !important;
    animation: fadein 2s cubic-bezier(0.22, 0.61, 0.63, 0.85);
    @media (max-width: 1200px) {
      margin-bottom: 2rem;
      width: 90%;
    }
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `
);

export const AccordionSection = ({
  data,
  color = "var(--white)",
  reverse = false,
  fancy = true,
  hideMobileImage = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { isMobile } = useIsMobile(1200);

  const handleTitle = () => {
    return fancy ? (
      <FancyTitleMed style={{ textAlign: "left", maxWidth: "553px" }}>
        {data.title}
      </FancyTitleMed>
    ) : (
      <TitleMed style={{ textAlign: "left", maxWidth: "553px" }}>
        {data.title}
      </TitleMed>
    );
  };

  return (
    <section className={sectionClass} style={{ background: color }}>
      <div
        className={contentClass}
        style={{ flexDirection: reverse ? "row-reverse" : "row" }}
      >
        <div className={accordionClass}>
          <div className={headerContainer}>
            {data.preTitle && <PreTitle>{data.preTitle}</PreTitle>}
            {handleTitle()}
          </div>
          <div className={textClass}>
            <ul>
              {data.items.map((item, index) => {
                return (
                  <SlideInContainer key={item.title}>
                    <li
                      className={
                        activeIndex === index ? borderActive : borderClass
                      }
                      onClick={() => {
                        setActiveIndex(index);
                      }}
                    >
                      <div
                        className={
                          activeIndex === index ? lineClassActive : lineClass
                        }
                      >
                        <h2
                          className={
                            activeIndex === index
                              ? subHeaderClassActive
                              : subHeaderClass
                          }
                        >
                          <span>{item.title}</span>
                          <div
                            className={
                              activeIndex === index ? openClass : closedClass
                            }
                          />
                        </h2>
                        <p
                          className={
                            activeIndex === index
                              ? paragraphClassActive
                              : paragraphClassInactive
                          }
                        >
                          {item.p}
                          <ul>
                            {item.listItems &&
                              item.listItems.map(i => <li className>{i}</li>)}
                          </ul>
                        </p>

                        {isMobile && (
                          <div className={imageWrapper}>
                            <GatsbyImage
                              image={getImage(item.img)}
                              alt={item.alt}
                              className={
                                activeIndex == index ? imgClassActive : imgClass
                              }
                            />
                          </div>
                        )}
                      </div>
                    </li>
                  </SlideInContainer>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={videoContainerClass}>
          {!isMobile &&
            data.items.map((item, index) => {
              const imageRef = getImage(item.img);
              return (
                <div
                  className={activeIndex == index ? imgClassActive : imgClass}
                  key={item.title}
                >
                  <GatsbyImage
                    image={imageRef}
                    alt={item.alt}
                    style={item.imgStyles}
                    objectFit="contain"
                  />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default AccordionSection;
