import React from "react";

const Arrow = ({ color = "currentColor", height = "25", width = "24" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M7 12.9126H17.5M17.5 12.9126L13.5 16.9126M17.5 12.9126L13.5 8.9126"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="0.75"
        y="1.6626"
        width="22.5"
        height="22.5"
        rx="11.25"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Arrow;
