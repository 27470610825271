import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";

import RhombusButton from "../RhombusButton";

const imageStyles = css`
  width: 100% !important;
`;

const BladeInner = styled(SectionInner)`
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const BladeBlurb = styled(MainParagraph)`
  font-weight: lighter;
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const Text = styled(TextContainer)`
  gap: 1.6rem;
  justify-content: center;
  @media (max-width: 820px) {
    align-items: center;
    text-align: center;
  }
`;

const CTABanner2 = ({
  title = "See Rhombus for Yourself",
  blurb = "Request a demo with our team today and see why industry leaders choose Rhombus",
}) => {
  return (
    <SectionContainer
      style={{
        position: "relative",
        minHeight: "500px",
        background: "var(--gradient-light)",
      }}
    >
      <BladeInner>
        <SectionCol style={{ zIndex: "2" }}>
          <Text>
            <FancyTitle fontSize="48px">{title}</FancyTitle>
            <BladeBlurb>{blurb}</BladeBlurb>
            <RhombusButton
              path="/live-demo/"
              type="primary"
              title="Request Demo"
            />
          </Text>
        </SectionCol>
        <SectionCol style={{ alignItems: "flex-end" }}>
          <StaticImage
            src="./images/cta-banner-2-min.png"
            className={imageStyles}
            alt="Poduim with two Rhombus cameras and a computer and phone showing the console"
            quality={100}
            placeholder="blurred"
            objectFit="contain"
          />
        </SectionCol>
      </BladeInner>
    </SectionContainer>
  );
};

export default CTABanner2;
